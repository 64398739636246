.ns-profile-powerup {
  padding: 0px;
  border-radius: 5px;
  height: 32;
  width: 32;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #e0ff8335 !important;
  }
  &:active {
    background-color: #dfff83 !important;
  }
}
