.user-twitter-sharing-info_modal {
  width: 414px !important;
  height: 608px;
  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.user-twitter-sharing-info_modal-content {
  padding: 24px 55px;
  height: 100%;
  justify-content: center;
}

.user-twitter-sharing-info_close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.user-twitter-sharing-info_close-icon {
  font-size: 18px;
  color: #888;
}

.user-twitter-sharing-info_modal-root-row {
  margin: 15px 0 45px 0;
  justify-content: center;
}
