.ns-search-user-ticker {
	min-width: 300px;
}

.ns-search-user-ticker-error {
	color: red;
	text-align: right;
	position: absolute;
}

.ns-search-user-ticker-field {
	cursor: pointer;
	text-align: right;
	background-color: white;
	border-radius: 10px;
	padding: 15px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: rgba(0, 0, 0, 0.2) !important;
}
