.full-screen-loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.691);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the z-index value as needed */
}

.loading-icon {
  height: 192px;
  width: 194px;
  background-color: transparent;
}
