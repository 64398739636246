@import "./typography.css";
@import "./buttons.css";
@import "./utils.css";
@import "./colors.css";
@import "./antd.css";

*,
input,
textarea,
.ant-input {
  font-feature-settings: normal;
  font-variant-numeric: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;

  pointer-events: none;
}

.spin-long {
  width: 100px;
  height: 100px;
  animation-name: spin;
  animation-duration: 210s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.34, 0.68, 0.69, 0.34);
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-content {
  font-size: 1em;
  /* min-height: 100%;
  height: 100%; */
  width: 100%;
  margin: 0px auto 24px auto;
  background: white;
  border-radius: 6px;
}

@media screen and (min-width: 450px) {
  .main-content {
    /* padding: 72px; */
    width: 400px;
  }
}

.instructions li {
  margin-top: 20px;
}

.copyDotenv {
  position: relative;
  top: -40px;
  left: -10px;
  text-align: right;
}

.header-padding {
  height: 42px;
  position: relative;
}

.logo {
  min-width: 50px;
}

a {
  font-family: NewfoundationWhyte-Light;
  transition: none;
}

.main-layout {
  margin: 0;
}

/* DAO */

.dao-details-btns > button:not(:last-child) {
  margin-bottom: 10px;
}

.std-row-wrapper {
  width: 300px;
}

.toggle-wrapper {
  justify-content: space-between;
}

.toggle-wrapper > .member-row-wrapper {
  margin-bottom: 20px;
}

.toggle-wrapper > .member-row-wrapper:not(:nth-child(5n)) {
  margin-right: 40px;
}

.toggle-wrapper > .member-row-wrapper:last-child {
  margin-right: 0px;
}

.dao-tabs-ctn {
  flex-wrap: wrap;
}

.dao-tabs-ctn > .sort {
  background: grey;
  border-radius: 4px;
  cursor: pointer;
}

.dao-tabs-ctn > .sort > svg {
  width: 20px;
  height: 20px;
}

/* Dropdown */

.app-dropdown-content-image > div {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.app-main-full-width {
  background: transparent !important;
}
/* TRANSACTION HISTORY */

.ns-transaction-history-wrapper .table > div:first-child {
  text-align: left;
}

.ns-transaction-history-wrapper .table-heading > div {
  font-weight: 600;
}
.ns-transaction-history-wrapper .table {
  margin: 15px auto;
  text-align: right;
}

/* HEADER */

.ns-header-logo:hover > .ns-header-logo__text {
  display: block;
}

.ns-header-logo__text {
  display: none;
}
