.ant-btn {
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: black;
  padding: 10px 26px;
  height: auto;
  font-size: 14px;
  font-weight: 300;
  box-shadow: none;
  margin: 5px;
  background-color: transparent;
}

/* default button */

.ant-btn-default {
  border: 1px solid #e4e4e4;
  border-radius: 6px;
}

.ant-btn-default:active,
.ant-btn-default:focus,
.ant-btn-default:hover {
  border-style: solid;
  border-color: #fff;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 15%);
  color: #000;
}

/* primary button (special) */

.ant-btn-primary {
  padding: 18px 70px;
  border: 1px none #e4e4e4;
  font-size: 21px;
  border-radius: 50px;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
}

.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  border-style: none;
  border-color: #fff;
  box-shadow: 0 0 10px 3px rgb(0 0 0 / 15%);
  color: #000 !important;
  /* TODO delete */
  background-color: transparent !important;
}

/* ghost button */

.ant-btn-ghost,
.ant-btn-ghost:hover,
.ant-btn-ghost:active,
.ant-btn-ghost:focus {
  color: black;
  border: 1px solid white;
  background-color: #fff;
  border-radius: 6px;
}

/* ghost button inverse */

.ant-btn-ghost.ant-btn-background-ghost {
  padding: 5px 10px;
}
.ant-btn.ant-btn-background-ghost:focus,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:hover {
  color: unset;
  border-color: unset;
}

/* disabled button */

.ant-btn[disabled] {
  background-color: #e4e4e4 !important;
  border: 1px none #e4e4e4;
}

/* select */

.ant-select {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 40px;
}
.ant-select-item {
  border-radius: 4px;
  align-items: center;
  height: 40px;
  padding: 5px 15px;
}
.ant-select-selector {
  color: black;
  height: 100%;
  align-items: center;
  outline: none;
  box-shadow: none !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #e1e1e1;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none;
  background-color: unset;
  box-shadow: unset;
}
.ant-select-dropdown {
  box-shadow: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 100%;
  padding: 5px 15px;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}
.ant-select-item-option {
  margin-bottom: 5px;
}
