/* LAYOUT */

.ant-layout {
  padding: 10px;
  background-color: white;
  height: calc(100%);
}

.ant-layout-has-sider {
  margin: 0;
}

.ant-layout-sider,
.ant-layout-sider-children,
.ant-menu-root.ant-menu-vertical {
  height: 100%;
  width: 177px;
  background-color: white !important;
  border: none;
  border-inline-end: unset !important;
}

.ant-menu-root.ant-menu-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.anticon svg {
  width: 27px;
  height: 27px;
}

/* HEADER */

.ant-layout-header {
  padding: 15px;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 998;
  background: transparent;
  line-height: initial;
  height: auto;
}

.ant-layout-header > div {
  align-items: center;
  width: 100%;
}

/* SIDER */

.ant-layout.ant-layout-has-sider > .ant-layout {
  width: auto;
}

@media only screen and (max-width: 768px) {
  .ant-layout-has-sider {
    flex-direction: column !important;
  }
}

.ant-layout-sider {
  position: fixed !important;
  color: white;
  height: calc(100vh - 120px - 25px);
  background-color: white;
  padding: 0;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-below {
  background: white;
}

.ant-slider:hover .ant-slider-rail,
.ant-slider .ant-slider-rail {
  background-color: transparent !important;
}

.ant-layout-sider-zero-width-trigger {
  background: rgba(255, 255, 255, 1);
  display: block;
  padding: 10px;
  padding-top: 10px;
  width: 40px;
  height: 40px;
}

.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger {
  top: 15px;
  left: 15px;
}

/* MENU */

.ant-menu,
.ant-menu ul,
.ant-menu li,
.ant-menu a:hover,
.ant-menu a:active,
.ant-menu a:link,
.ant-menu a:visited {
  color: black;
  text-decoration: none;
  display: flex;
  text-align: center;
  flex-direction: row;
}
a:visited,
a:active,
a:link,
a:hover {
  color: black;
}

.ant-menu-item:active {
  background-color: white;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: white;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: black;
  background-color: transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

/* TABS */

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  border: 1px solid black !important;
  border-radius: 6px;
}

.ant-tabs-nav-list {
  width: calc(100% - 2px);
  gap: 20px;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  width: 100%;
  justify-content: center;
  background: white;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border: 1px solid #e7e7e7;
  border-radius: 6px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover,
.ant-tabs-tab-btn:active {
  color: black !important;
}

.ant-tabs-tab {
  border-bottom: 1px transparent;
  color: #909090 !important;
  display: block;
  font-size: 23px !important;
  letter-spacing: -0.5px;
  line-height: 120%;
  padding-bottom: 0;
  text-decoration: none;
}

.ant-btn:not(:disabled):focus-visible {
  outline: unset !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border: unset;
}

/* SLIDER */

.ant-slider-step {
  height: 1px;
  border-radius: 0px;
  border: 1px solid black;
}

.ant-slider-handle:focus {
  box-shadow: none;
}

/* LINE */

.ant-menu-title-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-menu-title-content > a:before {
  content: "";
  width: 0%;
  height: 1px;
  display: block;
  background-color: black;
  top: 35px;
  position: absolute;
}
.ant-menu-title-content > a:hover::before,
.ant-menu .ant-menu-item-selected .ant-menu-title-content > a::before {
  width: 100%;
  transition: all 0.5s;
}

/* DRAWER */

.ant-drawer {
  z-index: 999999999;
}

.ant-drawer-header {
  border-bottom: 0 !important;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.ant-drawer-close,
.ant-drawer-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ns-antd-menu-item {
    text-align: center;
    font-size: 30px;
  }

  .ant-menu-item {
    width: 100%;
  }
}

.ant-drawer-content-wrapper {
  width: 100% !important;
}

/* FOOTER */

.ant-layout-footer {
  background-color: transparent;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 25px;
  padding: 0px;
}

.ant-layout-footer > div {
  padding: 0 15px;
}

/* ALERT */

.ant-alert {
  margin: 20px auto;
}
.ant-alert-message {
  text-align: center;
  color: #ea6418;
  height: auto;
  font-size: 14px;
}
.ant-alert-warning {
  background-color: transparent;
  border-color: #ea6418;
  border-radius: 6px;
  width: fit-content;
  padding: 10px 26px;
}

/* INPUT */

.ant-input,
.nl-onboarding-input {
  display: inline-block;
  padding: 10px 26px !important;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  border: 1px solid #e4e4e4 !important;
  font-size: 14px;
  border-radius: 6px;
  color: silver;
  text-align: center;
  box-shadow: none;
}

.ant-input::placeholder,
.nl-onboarding-input::placeholder {
  text-align: center;
}

.ant-input:focus,
.ant-input:active,
.nl-onboarding-input:active,
.nl-onboarding-input:focus {
  border: 1px solid #c4f634 !important;
  box-shadow: none;
}

.ant-input:hover,
.nl-onboarding-input:hover {
  border-color: #000000;
  box-shadow: none;
}

/* Progress bar */

.ant-progress-bg {
  background: black !important;
}

/* DAO */

.ant-dropdown-trigger {
  flex: 1;
}

.dao-tabs-ctn {
  margin: 40px 0;
}

/* MODAL */

.ant-modal {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}

.ant-modal-content {
  box-shadow: none;
}
.ant-modal-content,
.ant-modal-header {
  border-radius: 8px;
}

.ant-modal-body .nl-button-primary {
  margin-bottom: 10px;
}

.ant-modal-header {
  border-bottom: 0px;
}
.ant-modal-footer {
  border-top: 0px;
}
.ant-modal-body {
  padding: 4px 24px;
}

/* Tooltip */
.ant-tooltip-inner {
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 6.30446px;
  box-shadow: none;
  color: #959595;
  width: 378px;
  height: auto;
  padding: 10px 30px;
  text-align: center;
}
.ant-tooltip-arrow {
  display: none;
}

/* IMAGE */
.ant-select-item-option-content .ant-row .ant-col .ant-image-img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.ant-image-img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-image-size {
  border-radius: 100%;
}
.ant-image-size > div {
  width: 100%;
}
.small-profile-icon .ant-image-size,
.small-profile-icon .ant-image-img {
  height: 40px;
}

/* Avatar */

.ant-avatar {
  width: 100%;
  height: 100%;
}

/* Button */

.ant-btn:active {
  box-shadow: none !important;
  outline: none !important;
  outline-color: transparent;
  outline-style: none;
  box-shadow: none;
}

.ant-btn:hover {
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background: transparent;
}
