.ant-upload.ant-upload-select-picture-card,
.ant-upload.ant-upload-select-picture-card:hover {
  border: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
}

.ant-upload-list-item-actions:hover {
  background-color: transparent;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  top: 0;
  right: 0;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: inherit;
}
