.u-margin-zero {
  margin: 0px;
}

.u-margin-right-xs {
  margin-right: 5px;
}

.u-margin-left-xs {
  margin-left: 5px;
}

.u-margin-bottom-xs {
  margin-bottom: 5px;
}

.u-margin-top-xs {
  margin-top: 5px;
}

.u-margin-right-small {
  margin-right: 10px;
}

.u-margin-left-small {
  margin-left: 10px;
}

.u-margin-bottom-small {
  margin-bottom: 10px;
}

.u-margin-top-small {
  margin-top: 10px;
}

.u-margin-right-medium {
  margin-right: 20px;
}

.u-margin-left-medium {
  margin-left: 20px;
}

.u-margin-bottom-medium {
  margin-bottom: 20px;
}

.u-margin-top-medium {
  margin-top: 20px;
}

.u-margin-top-large {
  margin-top: 40px;
}

.u-margin-right-large {
  margin-right: 40px;
}

.u-margin-left-large {
  margin-left: 40px;
}

.u-margin-bottom-large {
  margin-bottom: 40px;
}

.u-margin-bottom-mega {
  margin-bottom: 80px;
}

.u-margin-top-mega {
  margin-top: 80px;
}

.u-margin-right-mega {
  margin-right: 80px;
}

.u-margin-left-mega {
  margin-left: 80px;
}

.u-margin-right-auto {
  margin-right: auto;
}

.u-margin-left-auto {
  margin-left: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: column;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.vertical-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.vertical-space-children > * {
  margin-top: 20px;
  margin-bottom: 20px;
}

.full-width {
  width: 100% !important;
}

.u-center-position-absolute {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  height: inherit;
}

.word-break-all {
  word-break: break-all;
}

.horizontal-margins-auto {
  margin-left: auto;
  margin-right: auto;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
