.user-invite-info_modal {
  width: 400px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.user-invite-info_modal-content {
  padding: 24px;
  justify-content: center;
}

.user-invite-info_close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.user-invite-info_close-icon {
  font-size: 18px;
  color: #888;
}

.user-invite-info_modal-root-row {
  margin: 16px 0;
  justify-content: center;
}
