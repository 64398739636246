@import "./fonts/fonts.css";

.super-size {
  font-family: NewfoundationWhyte-Bold;
  font-size: 120px;
  line-height: 156px;
  letter-spacing: -0.3px;
}
@media screen and (max-width: 428px) {
  .super-size {
    font-size: 60px;
    text-align: center;
    line-height: 65px;
  }
}

/* Paragraphs */

p,
.paragraph-1 {
  font-family: NewfoundationWhyte-Regular;
  margin: 0px;
  color: #000;
  font-size: 16px !important;
  line-height: 120%;
}

.paragraph-1large {
  font-family: NewfoundationWhyte-Bold;
  color: #000;
  font-size: 21px !important;
  line-height: 140%;
  font-weight: 100;
}

.paragraph-1b {
  font-family: NewfoundationWhyte-Bold;
  margin: 0;
  color: #000;
  font-size: 16px !important;
  line-height: 120%;
}

.paragraph-2 {
  font-family: NewfoundationWhyte-Regular;
  margin: 0;
  color: #000;
  font-size: 12px !important;
  line-height: 120%;
}

.paragraph-2b {
  font-family: NewfoundationWhyte-Bold;
  margin: 0;
  color: #000;
  font-size: 12px !important;
  line-height: 120%;
}

.paragraph-3 {
  font-family: NewfoundationWhyte-Regular;
  margin: 0;
  color: #000;
  font-size: 10px !important;
  line-height: 120%;
}

/* Headers */

.header-1 {
  font-family: NewfoundationWhyte-Regular;
  display: block;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 1px none transparent;
  color: #000;
  font-size: 60px !important;
  line-height: 120%;
  font-weight: 100;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.header-1 {
  font-family: NewfoundationWhyte-Regular;
  display: block;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 1px none transparent;
  color: #000;
  font-size: 30px !important;
  line-height: 120%;
  font-weight: 100;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.header-1b {
  font-family: NewfoundationWhyte-Bold;
  display: block;
  border-bottom: 1px none transparent;
  color: #000;
  font-size: 30px !important;
  line-height: 120%;
  font-weight: 100;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.header-2 {
  font-family: NewfoundationWhyte-Regular;
  display: block;
  color: #000;
  font-size: 26px !important;
  line-height: 140%;
  font-weight: 100;
}

.header-2b {
  font-family: NewfoundationWhyte-Bold;
  display: block;
  color: #000;
  font-size: 26px !important;
  line-height: 140%;
  font-weight: 100;
}

/* utility */

.typography-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 90%; */
}
.text-grey {
  font-weight: 400;
  font-size: 19px;
  color: #c1c1c1;
}
.text-white {
  color: white;
}
