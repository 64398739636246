.ns-markdown img {
  margin: 20px 0px;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.ns-markdown p {
  text-align: center;
}
