.text-modest {
	color: #c0c0c0 !important;
}

.text-modest a:visited,
.text-modest a:active,
.text-modest a:link,
.text-modest a:hover {
	color: #c0c0c0 !important;
}

/* text */

.text-purple {
	color: "#9DA9DF";
}
.text-earch {
	color: "#645F50";
}
.text-green {
	color: "#CDE0A5";
}
.text-volt {
	color: "#E9FF91";
}
.text-offWhite {
	color: "#D2D6D1";
}
.text-coral {
	color: "#F49577";
}
.text-beige {
	color: "#DBCD9A";
}
.text-brown {
	color: "#D4B1A7";
}

/* background */

.bg-purple {
	background-color: "#9DA9DF";
}
.bg-earch {
	background-color: "#645F50";
}
.bg-green {
	background-color: "#CDE0A5";
}
.bg-volt {
	background-color: "#E9FF91";
}
.bg-offWhite {
	background-color: "#D2D6D1";
}
.bg-coral {
	background-color: "#F49577";
}
.bg-beige {
	background-color: "#DBCD9A";
}
.bg-brown {
	background-color: "#D4B1A7";
}
