.ns-contentCard-wrapper > div:not(:last-child) {
  margin-bottom: 20px;
}
.ns-contentCard-image {
  position: static;
  margin: 0 auto;
  display: block;
  width: 500px;
  height: 500px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 0%;
  object-position: 50% 0%;
}
@media screen and (max-width: 528px) {
  .ns-contentCard-image {
    width: 100%;
    height: 100%;
  }
}
.ns-contentCard-container {
  border-radius: 8px;
  border: 0.25px solid #6a6a6a;
  background-color: #ffff;
  width: 100%;
  height: fit-content;
}
.ns-contentCard-content {
  padding: 15px;
}

.ns-contentCard-container:not(:last-child) {
  margin-bottom: 20px;
}

.ns-contentCard-container__logo {
  background-color: white;
  width: 45px;
  height: 45px;
  position: relative;
}

.ns-contentCard-container__logo img {
  border-radius: 10px;
}

@media screen and (max-width: 528px) {
  .ns-contentCard-container__logo {
    width: 40px;
    height: 40px;
  }
}

.ns-contentCard-title__wrapper {
  width: 70%;
  color: #fff;
}

.ns-content-card-custom-btn {
  position: relative;
  height: 84px;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid #d7d7d7;
  &:hover {
    background-color: #e0ff8335;
    border-radius: 0px 6.5px 6.5px 0px;
  }

  &:active {
    background-color: #dfff83;
  }
}
