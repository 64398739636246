.share-button-icon {
  cursor: pointer;
  text-align: center;
  width: 80px;

  @media screen and (max-width: 428px) {
    margin: 0;
  }
}

.white-box-modal {
  background-color: white;
  padding: 24px;
  border-radius: 20px;
}

.copy-to-clipboard-button {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.ant-modal-close-x,
.ant-modal-close-x:hover,
.ant-modal-close-x:focus,
.ant-modal-close-x:active {
  background-color: white;
}
