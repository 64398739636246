/* app-specific styles */

* {
  font-feature-settings: normal;
  font-variant-numeric: normal;
}

body {
  background-color: #fff;
}

::selection {
  background: #f9f9f9;
  color: black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-layout {
  padding: 15px;
  padding-top: 26px;
}

.ant-layout-content {
  /* height: calc(100vh - 140px - 25px); */
}

.main-content {
  z-index: 999 !important;
  position: relative;
}

@media screen and (max-width: 850px) {
  .ant-layout {
    padding: 20px;
    padding-top: 100px;
  }

  .ant-layout-content {
    /* height: calc(100vh - 100px - 25px); */
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* AUTH PAGE */

.oauth-buttons-wrapper {
  display: flex;
  flex-direction: column;
  width: 312px;
  margin: 0 auto;
}

.oauth-buttons-wrapper > button:not(:last-child) {
  margin-bottom: 20px;
}

.ns-socialButton {
  width: 300px;
}

/* STAKE */

.stake-card-wrapper {
  position: relative;
  border-radius: 4px;
}
.stake-input-square {
  background-color: #f9f9f9;
  border-radius: 10px;
}
.stake-card-wrapper > .stake-input-square {
  padding: 0px 40px;
  margin-bottom: 10px;
  height: 120px;
}

@media screen and (max-width: 768px) {
  .stake-card-wrapper > .stake-input-square {
    padding: 0px 10px;
  }
}

.stake-input-square > div {
  padding: 20px 0;
}

.stake-input-flip {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 40%;
}

/* Badges */

.ns-badges-wrapper > .ns-badge {
  /* flex-grow: 1; */
  width: 25%;
  /* width: 33%; */
  /* width: auto; */
}

@media screen and (max-width: 768px) {
  .ns-badges-wrapper > .ns-badge {
    width: 50%;
  }
}

@media screen and (max-width: 428px) {
  .ns-badges-wrapper > .ns-badge {
    width: 100%;
  }
}

.ns-badge {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ns-badge img {
  width: 158px;
  height: 158px;
  border-radius: 50%;
}

/* Wallet */
.wallet-tokens-row {
  margin-bottom: 20px;
  min-height: 70px;
  /* border: 1px solid grey;  */
}

.wallet-tokens-row > div {
  min-height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
}

.small-profile-icon {
  text-align: "center";
  border-radius: 100%;
  overflow: "hidden";
  width: 40px;
  height: 40px;
}

canvas {
  width: 100%;
  height: 100%;
}

/* DAO */

.dao-tabs-ctn {
  display: flex;
  justify-content: start;
}

.dao-tabs-ctn a.ant-dropdown-trigger,
.dao-tabs-ctn a.ant-dropdown-trigger:hover,
.dao-tabs-ctn a.ant-dropdown-trigger:active,
.dao-tabs-ctn a.ant-dropdown-trigger:link,
.dao-tabs-ctn a.ant-dropdown-trigger:visited {
  color: black;
}

.dao-tabs-ctn > :not(h2) {
  padding: 20px;
}

.dao-tabs-ctn h2 {
  font-size: 1em;
  display: inline-block;
  padding: 10px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: -1px;
}

.dao-tabs-ctn h2.menu-h2-inactive {
  border-bottom: 1px solid black;
}

.dao-tabs-ctn .menu-h2-active {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  /* border-bottom: 1px solid black; */
}

.view-proposal-row-title-p {
  display: block;
  width: 100%;
}

.ns-user-header-outline {
  padding: 0 31px;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
  height: 43px;
}
.ns-user-header-outline > span {
  display: flex;
  align-items: center;
  height: 43px;
}

/* @media screen and (max-width: 528px) {
  .ns-myApps-title__wrapper {
    width: 60%;
  }
} */

/* Modal - swap page*/
#searchUserTicker {
  overflow: inherit !important;
}
#searchUserTicker.ant-scrolling-effect {
  height: 69.28px;
}
@media screen and (max-width: 428px) {
  #searchUserTicker.ant-scrolling-effect {
    height: 61.43px;
  }
}

#searchUserTicker .ant-modal {
  top: 0px;
}
@media screen and (max-width: 1024px) {
  #searchUserTicker .ant-modal {
    top: 0px;
    right: -17px;
    position: absolute;
  }
}
#searchUserTicker .ant-modal-wrap {
  position: absolute;
  overflow: inherit;
  outline: 0;
  top: 0px;
  -webkit-overflow-scrolling: touch;
}
#searchUserTicker .ant-modal-content {
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

#area {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  justify-content: right;
}

#area .ant-select {
  border: none;
}

#area > div ~ div,
#area .ant-select-dropdown {
  position: relative !important;
}

/* GETTING STARTED */

.card-square-container .ant-col {
  min-width: 50%;
}
.card-square {
  font-weight: bold;
  min-width: min(50vw, 50%);
  min-height: 100px;
  padding-top: 15px;
  border: 1px solid grey;
  transition: 500ms;
  color: #555555;
}

.card-square:hover {
  transition: 500ms;
  background-color: #efefef;
  color: black;
}
/* POOL */

.ns-pool-wrapper > div {
  justify-content: space-between;
}

/* Transitions */
.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Tabs */
.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-tab {
  font-family: NewfoundationWhyte-Regular;
  display: block;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 1px none transparent;
  color: #909090;
  line-height: 120%;
  letter-spacing: -0.5px;
  text-decoration: none;
}

.ant-tabs-tab:not(:first-of-type) {
  margin-left: 20px !important;
}

.ant-tabs-tab-active {
  font-family: NewfoundationWhyte-regular;
  display: block;
  border-bottom: 1px none transparent;
  color: #000;
  line-height: 120%;
  letter-spacing: -0.5px;
  text-decoration: none;
}
.ant-tabs-nav-list {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.ant-tabs-nav-operations {
  display: none !important;
}

/* Inputs */
.ant-input {
  border-radius: 50px !important;
  height: 70px;
  font-size: 16px;
}

.PhoneInput {
  display: flex !important;
}

.PhoneInputCountry {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}

.PhoneInputInput,
.PhoneInputInput:focus,
.PhoneInputInput:active,
.PhoneInputInput:hover,
.PhoneInputInput:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
  height: 100%;
  font-size: 16px !important;
  text-align: center;
  padding: 10px 26px !important;
  transition: all 200ms ease;
  color: silver !important;
  width: 100%;
  font-family: NewfoundationWhyte-Regular;
}

/* Icons */
.spinner > .anticon svg {
  height: 50px;
  width: 50px;
}

/* Upload */
.ant-upload-list-picture-card-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: 0px 0px 34px 0px #dfff83;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
}

.ant-upload-list-item-info {
  border-radius: 50%;
}

.ant-upload.ant-upload-select {
  border: none !important;
  width: 150px !important;
  height: 150px !important;
  background-color: transparent !important;
}

/* Messages */
.ant-message-notice-content {
  background-color: transparent !important;
}

/* Drawer */
.ant-layout-sider,
.ant-layout-sider-children,
.ant-menu-root.ant-menu-vertical {
  width: 177px;
  background-color: white !important;
  border: none;
  border-inline-end: unset !important;
}

.ns-antd-menu-item {
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: row;
  font-size: 20px;
}

.ant-menu-vertical .ant-menu-item {
  padding: 0px;
  line-height: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 177px;
}

.ns-antd-menu-item {
  width: 177px;
  justify-content: left;
}

.ant-drawer .ant-drawer-close {
  margin-inline-end: 0;
  padding: 0;
}

@media screen and (max-width: 850px) {
  .ant-layout-sider,
  .ant-layout-sider-children,
  .ant-menu-root.ant-menu-vertical {
    width: 100% !important;
    max-width: 356px !important;
  }
  .ns-antd-menu-item {
    width: 100% !important;
    text-align: center;
  }
  .ant-menu-title-content {
    justify-content: center;
  }

  .ant-menu-vertical .ant-menu-item {
    width: 100%;
  }
}

/* Checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}

/* Button */
.ant-btn:active,
.ant-btn:focus {
  color: #000000 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

/* Infinite Scroll */
.ns-infinite-scroll {
  scrollbar-width: none;
}

.ns-infinite-scroll::-webkit-scrollbar {
  display: none;
}

/* Radio buttons */
.ant-radio-button-wrapper:first-of-type {
  padding-left: 0px !important;
}

.ant-radio-button-wrapper {
  border: none !important;
  color: #909090 !important;
  font-size: 19px;
}
.ant-radio-button-wrapper-checked {
  border: none !important;
  color: black !important;
  font-size: 19px;
}

.ant-radio-button-wrapper::before {
  display: none !important;
}

/* Collapse */
.ant-collapse-header {
  flex-direction: row-reverse !important;
  padding: 12px 0 !important;
}

.ant-collapse-content-box {
  padding: 16px 0 !important;
}

.ant-collapse-header-text {
  padding-right: 20px;
}

.ant-collapse-expand-icon {
  padding: 0 !important;
}

.ant-collapse {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.ant-collapse-item {
  border-bottom: 0.5px solid #aeaeae;
  border-radius: 0;
  padding: 20px 0px !important;
}

.ant-collapse-item:first-of-type {
  border-top: 0.5px solid #aeaeae;
}

.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse-item:last-child {
  border-radius: 0 !important;
}

.ns-notification-button:hover {
  background-color: white !important;
}
