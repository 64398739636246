/* TODO: cleanup unused styling, imported from Webflow */

.div-block-2 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 3px;
  display: flex;
}

.text-block-2 {
  color: #000;
  text-align: center;
  letter-spacing: 0.2px;
  margin-bottom: 12px;
  font-size: 10px;
  line-height: 14px;
}

.symbol-footer {
  width: 25px;
  margin-bottom: 12px;
  margin-left: 6px;
  margin-right: 2px;
}

.link-block {
  text-decoration: none;
  display: block;
}

.text-block-2-copy {
  color: #000;
  text-align: center;
  letter-spacing: 0.2px;
  margin-bottom: 0;
  font-size: 10px;
  line-height: 14px;
}

.link-top-2 {
  color: #000;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 7px 15px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.link-top-2:hover {
  border-color: #5c5c5c;
}

.footer-2 {
  width: 100vw;
  height: 150px;
  background-color: #f6f6f6;
  display: none;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.image-3 {
  width: 110px;
  height: auto;
}

.text-block-3 {
  color: #000;
  letter-spacing: -0.5px;
  border-bottom: 1px rgba(0, 0, 0, 0);
  margin-bottom: 25px;
  padding-bottom: 4px;
  font-size: 28px;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
  display: block;
}

.text-block-3:hover {
  border-bottom: 1px #000;
}

.text-block-3.grey {
  color: silver;
  margin-bottom: 0;
  font-weight: 100;
  text-decoration: none;
  position: fixed;
  top: auto;
  bottom: 15px;
  left: 20px;
  right: auto;
}

.menu {
  width: 150px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
  display: flex;
}

.small {
  font-size: 11px;
  line-height: 100%;
  position: fixed;
  top: auto;
  bottom: 20px;
  left: 25px;
  right: auto;
}

.small.right {
  top: auto;
  bottom: 20px;
  left: auto;
  right: 25px;
}

.body {
  padding: 20px 25px 25px 20px;
  font-family: Newfoundation Whyte, sans-serif;
  font-weight: 300;
}

.body.newlife {
  padding: 0;
}

.logo-menu {
  width: 220px;
  position: fixed;
}

.logo-menu.nl {
  z-index: 100;
  width: 130px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.div-block-7 {
  width: 800px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.font-small-x {
  color: #000;
  text-align: left;
  letter-spacing: 0.2px;
  font-size: 14px;
  font-weight: 200;
  line-height: 17px;
}

.font-small-x.white {
  color: #fff;
}

.font-small-x.left {
  text-align: left;
  font-size: 15px;
}

.font-small-x.shadow {
  color: #fff;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 37px;
  line-height: 50px;
}

.font-small-x.shadow.center {
  text-align: center;
}

.font-small-x.nominated {
  font-size: 12px;
  font-weight: 100;
}

.font-small-x.nominated.by {
  margin-right: 4px;
}

.font-small-x.center {
  text-align: center;
  font-weight: 400;
}

.font-small-x.center.big {
  margin-right: 4px;
  font-weight: 300;
}

.font-small-x.lighter {
  font-weight: 300;
  line-height: 20px;
}

.font-small-x.lighter.textbox {
  height: auto;
  border-style: none;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  position: static;
  bottom: 0;
}

.font-small-x.lighter.nf {
  margin-top: 5px;
}

.font-small-x.bolder {
  margin-bottom: 12px;
  font-weight: 400;
}

.font-small-x.headline {
  margin-bottom: 15px;
  font-weight: 400;
}

.font-small-x.caption {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
}

.font-small-x.caption.user {
  margin-left: 0;
  font-weight: 100;
}

.image-4 {
  width: 20px;
  display: none;
  position: fixed;
  top: 30px;
  bottom: auto;
  left: auto;
  right: 25px;
}

.button-1 {
  color: #000;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 9px 23px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.button-1:hover {
  color: #000;
  border-style: solid;
  border-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.button-1.round {
  border-radius: 110px;
  padding: 6px 20px;
  font-size: 13px;
}

.spacer {
  width: 100%;
  height: 20px;
}

.button-text {
  color: #000;
  text-align: center;
  background-color: #e4e4e4;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 10px 30px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 15px;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.button-text:hover {
  background-color: #d1d1d1;
  border-color: rgba(0, 0, 0, 0);
}

.input {
  width: 270px;
  color: silver;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 7px 15px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.input:hover {
  border-color: #5c5c5c;
}

.active-field {
  width: 270px;
  color: #000;
  text-align: center;
  border: 1px solid #c4f634;
  border-radius: 4px;
  padding: 7px 15px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
}

.active-field:hover {
  border-color: #c4f634;
}

.error-message {
  width: 270px;
  color: #f49577;
  text-align: center;
  border: 1px solid #f49577;
  border-radius: 4px;
  padding: 7px 15px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
}

.error-message:hover {
  border-color: #ea6418;
}

.go-to-newlink {
  color: #000;
  font-size: 11px;
  line-height: 100%;
  display: none;
  position: fixed;
  top: 8%;
  bottom: auto;
  left: auto;
  right: 25px;
}

.go-to-newlink.margin {
  margin-top: 20px;
}

.button-right-top {
  color: #000;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-top: 30px;
  margin-right: 25px;
  padding: 7px 15px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.button-right-top:hover {
  border-color: #5c5c5c;
}

.main-container {
  width: 800px;
  height: 100%;
  border: 1px #e4e4e4;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.logo-bigger {
  width: 200px;
  height: auto;
  margin-bottom: 60px;
}

.div-block-8 {
  z-index: 2;
  width: 80vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.text-block-6 {
  width: 705px;
  text-align: center;
  margin-bottom: 70px;
  font-size: 32px;
  line-height: 120%;
}

.div-block-9 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  display: flex;
}

.div-block-11 {
  width: 400px;
  border: 0.5px solid #000;
}

.div-block-12 {
  width: 18px;
  height: 18px;
  cursor: grab;
  background-color: #000;
  border-radius: 20px;
  margin-left: -370px;
}

.div-block-13 {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.link-2 {
  color: #000;
  text-decoration: none;
}

.image-5 {
  width: 500px;
  height: 500px;
  object-fit: cover;
  object-position: 50% 0%;
  border-radius: 10px;
  display: block;
  position: static;
}

.div-block-14 {
  width: 500px;
  height: 120px;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: rgba(0, 0, 0, 0.01);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: -120px;
  padding: 18px;
  display: flex;
}

.div-block-15 {
  flex-direction: column;
  display: flex;
}

.div-block-16 {
  width: 200px;
  height: 110px;
  margin-top: 230px;
}

.image-6 {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.link-button-app {
  width: 80px;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 5px 0;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
}

.link-button-app:hover {
  border-color: #fff;
}

.text-block-7 {
  width: 320px;
  color: #fff;
  font-size: 13px;
  font-weight: 100;
  line-height: 140%;
}

.div-block-17 {
  align-items: center;
  display: flex;
}

.div-block-18 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.text-block-8 {
  color: #fff;
  margin-bottom: -7px;
  font-size: 13px;
  font-weight: 100;
}

.div-block-19 {
  flex-direction: column;
  display: flex;
}

.text-block-7-copy {
  width: 320px;
  color: #fff;
  margin-bottom: 2px;
  font-size: 21px;
  font-weight: 100;
  line-height: 140%;
}

.button-right-top-copy-copy {
  width: 290px;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 1px #e4e4e4;
  border-radius: 6px;
  margin-top: 0;
  margin-bottom: 30px;
  margin-right: 25px;
  padding: 15px 0;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 17px;
  font-weight: 200;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
  box-shadow: 1px 0 17px rgba(0, 0, 0, 0.12);
}

.button-right-top-copy-copy:hover {
  background-color: #f8f8f8;
  border-color: #5c5c5c;
  transform: translate(0, -1px);
  box-shadow: 2px 0 22px rgba(0, 0, 0, 0.25);
}

.image-3-copy {
  width: 35px;
  height: auto;
  margin-right: 2px;
}

.button-2x {
  color: #000;
  text-align: center;
  border: 1px #e4e4e4;
  border-radius: 30px;
  padding: 18px 70px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 21px;
  font-weight: 100;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.button-2x:hover {
  color: #000;
  border-style: none;
  border-color: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
}

.h1 {
  color: #000;
  letter-spacing: -0.5px;
  border-bottom: 1px rgba(0, 0, 0, 0);
  margin-top: 75px;
  margin-bottom: 0;
  margin-left: 110px;
  padding-bottom: 0;
  font-size: 28px;
  font-weight: 100;
  line-height: 120%;
  text-decoration: none;
  display: block;
}

.h1:hover {
  border-bottom: 1px #000;
}

.h1.nl {
  letter-spacing: 0;
  margin-top: 0;
  margin-left: 62px;
  margin-right: 10px;
  font-size: 23px;
  font-weight: 100;
  display: inline-block;
}

.h1.nl.romie {
  color: #000;
  letter-spacing: 0;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #fff;
  font-family: Romie, sans-serif;
  font-weight: 400;
}

.h1.nl.analy {
  margin-left: 0;
}

.font-small {
  color: #000;
  letter-spacing: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 15px;
  font-weight: 100;
  line-height: 100%;
  text-decoration: none;
  display: block;
}

.font-small:hover {
  border-bottom: 1px solid #000;
}

.font-small.corner {
  letter-spacing: -0.1px;
  font-size: 17px;
  font-weight: 400;
}

.font-small.white {
  color: #fff;
}

.button-input {
  color: silver;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  padding: 9px 23px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.button-input:hover {
  border-style: solid;
  border-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.nav-right {
  width: 200px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  position: fixed;
  top: 20px;
  bottom: auto;
  left: auto;
  right: 20px;
}

.nav-right.newlife {
  justify-content: flex-end;
}

.font-extra-small {
  width: 210px;
  letter-spacing: 0.1px;
  margin-bottom: 0;
  font-size: 10px;
  line-height: 13px;
}

.font-extra-small.right-bottom {
  margin-bottom: 0;
  position: fixed;
  top: auto;
  bottom: 15px;
  left: auto;
  right: 15px;
}

.font-extra-small.right {
  text-align: right;
  margin-bottom: 0;
  font-size: 11px;
}

.font-extra-small.white {
  width: 210px;
  min-width: 0;
  color: #fff;
  letter-spacing: 0.1px;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 100;
  line-height: 13px;
}

.h2-x {
  width: auto;
  color: #000;
  margin-right: 5px;
  font-size: 22px;
  font-weight: 100;
}

.h2-x.white {
  width: auto;
  color: #fff;
  margin-bottom: 0;
  margin-right: 5px;
  font-size: 22px;
  display: inline;
}

.h2-x.white.black {
  color: #000;
}

.button-input-active {
  color: #000;
  text-align: center;
  border: 1px solid #e9ff91;
  border-radius: 6px;
  padding: 9px 23px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.button-input-error {
  color: #f49577;
  text-align: center;
  border: 1px solid #f49577;
  border-radius: 6px;
  padding: 9px 23px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.button-disabled {
  color: silver;
  text-align: center;
  background-color: #e4e4e4;
  border: 1px #e4e4e4;
  border-radius: 6px;
  padding: 9px 23px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
}

.image-7 {
  width: 37px;
}

.image-8 {
  height: 15px;
}

.image-9 {
  height: 17px;
  display: none;
}

.folder-row {
  width: 94vw;
  justify-content: space-between;
  margin-top: 35px;
  margin-left: 0;
  display: flex;
}

.div-block-21 {
  width: 30%;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
}

.div-block-21:hover {
  transform: translate(0, -3px);
}

.image-10 {
  width: 93px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.spacer-grid {
  width: 30px;
  height: 20px;
}

.folder {
  width: 6.5vw;
  cursor: pointer;
  margin-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.div-block-7-copy {
  width: 800px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.image-10-copy {
  width: 100px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.image-10-copy-copy {
  width: 55px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.blur-layer {
  z-index: 99;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: rgba(255, 255, 255, 0);
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.h1-romie {
  width: auto;
  color: #000;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-family: Romie, sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 110%;
  display: block;
}

.h1-romie.plus {
  display: inline;
}

.div-block-22 {
  height: 300px;
}

.spacer-150 {
  width: 100%;
  height: 100px;
}

.button-romie {
  width: 300px;
  color: #000;
  text-align: center;
  letter-spacing: 0.3px;
  border: 1px #e4e4e4;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  font-family: Romie, sans-serif;
  font-size: 36px;
  font-weight: 100;
  text-decoration: none;
  transition: all 0.2s;
  display: block;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.button-romie:hover {
  color: #000;
  border-style: none;
  border-color: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
}

.square-card {
  width: 8rem;
  height: 8rem;
  background-color: rgba(223, 255, 131, 0.2);
  border: 0.5px solid #000;
  border-radius: 8px;
  flex: none;
  margin-right: 10px;
}

.square-card._20 {
  background-color: rgba(223, 255, 131, 0.3);
}

.square-card._30 {
  background-color: rgba(223, 255, 131, 0.4);
}

.square-card._40 {
  background-color: rgba(223, 255, 131, 0.5);
}

.square-card._50 {
  background-color: rgba(223, 255, 131, 0.6);
}

.square-card._60 {
  background-color: rgba(223, 255, 131, 0.7);
}

.square-card._70 {
  background-color: rgba(223, 255, 131, 0.8);
}

.square-card._80 {
  background-color: #dfff83;
}

.image-11 {
  height: 5rem;
}

.card-center {
  width: 100%;
  height: 6rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-25 {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.small-icon {
  width: 1.1rem;
  height: auto;
  margin-bottom: 5px;
  display: block;
}

.card-bottom {
  width: 100%;
  height: 1rem;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 6px;
  display: flex;
}

.card-top {
  width: 100%;
  height: 1rem;
  justify-content: flex-end;
  align-items: center;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.font-extra-small-copy {
  margin-bottom: 30px;
  font-size: 11px;
}

.font-extra-small-copy.right-bottom {
  margin-bottom: 0;
  position: fixed;
  top: auto;
  bottom: 15px;
  left: auto;
  right: 15px;
}

.font-extra-small-copy.right {
  text-align: right;
  margin-bottom: 0;
}

.spacer-30 {
  width: 100%;
  height: 30px;
}

.small-icon-right {
  height: 19px;
  opacity: 0.4;
  margin-left: 0;
  margin-right: 5px;
}

.small-icon-right._100 {
  height: 19px;
  opacity: 1;
}

.text-span {
  margin-top: 5px;
  font-family: Newfoundation Whyte Book, sans-serif;
  display: inline-block;
}

.spacer-20 {
  width: 100%;
  height: 15px;
}

.font-small-subnavi {
  color: #000;
  text-align: left;
  letter-spacing: 0.3px;
  cursor: pointer;
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 17px;
}

.font-small-subnavi.white {
  color: #fff;
}

.font-small-subnavi.left {
  text-align: left;
  margin-bottom: 5px;
  font-size: 14px;
}

.font-small-subnavi.grey {
  color: #b1b1b1;
}

.font-small-subnavi.grey:hover {
  color: #000;
}

.div-block-26 {
  width: 100%;
  height: 106px;
  border: 1px solid #bbb;
  border-radius: 12px;
  align-items: center;
  display: flex;
}

.image-12 {
  width: 260px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.watts-bar {
  width: 30%;
  height: 86px;
  background-color: #dfff83;
  border: 1px #bbb;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 9px;
  transition: all 0.2s;
  display: block;
  position: absolute;
}

.watts-bar:hover {
  filter: blur(8px);
}

.image-13 {
  z-index: 23;
  height: 58px;
  margin-left: 15px;
  position: relative;
}

.div-block-26-copy-copy {
  width: 100%;
  height: 86px;
  border: 1px #bbb;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
  display: flex;
}

.image-13-copy {
  height: 58px;
  margin-left: 0;
  margin-right: 15px;
}

.font-small-x-apps-button {
  color: #000;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #bbb;
  border-radius: 40px;
  margin-bottom: 12px;
  margin-right: 12px;
  padding: 14px 28px;
  font-size: 18px;
  font-weight: 100;
  line-height: 17px;
  transition: all 0.2s;
  display: inline-block;
}

.font-small-x-apps-button:hover {
  border-color: #585858;
  transform: scale(1.05);
}

.font-small-x-apps-button.white {
  color: #fff;
}

.font-small-x-apps-button.left {
  text-align: left;
  font-size: 15px;
}

.slider-2x {
  width: 100%;
  height: auto;
  clear: none;
  background-color: rgba(0, 0, 0, 0);
  margin-left: auto;
  margin-right: auto;
}

.newheadline {
  z-index: 100;
  color: #e9ff91;
  text-align: center;
  letter-spacing: -0.5px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  margin: 15px auto 0;
  font-size: 39px;
  font-weight: 400;
}

.left-arrow,
.right-arrow,
.slide-nav {
  display: none;
}

.image-14 {
  width: 1180px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.image-15 {
  width: 1250px;
  margin-top: -30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.div-block-27 {
  width: 830px;
  margin-left: auto;
  margin-right: auto;
}

.h1-_-front {
  color: #000;
  letter-spacing: -0.2px;
  border-bottom: 1px rgba(0, 0, 0, 0);
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 25px;
  font-weight: 200;
  line-height: 32px;
  text-decoration: none;
  display: block;
}

.h1-_-front:hover {
  border-bottom: 1px #000;
}

.image-16 {
  height: 45px;
  margin-top: -26px;
  margin-left: 10px;
  display: inline-block;
}

.div-block-28 {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.image-17 {
  width: 800px;
}

.text-block-9 {
  font-size: 56px;
}

.wts {
  color: #000;
  font-size: 56px;
  display: inline-block;
}

.div-block-29 {
  width: 390px;
  height: 90px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.div-block-30 {
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-18 {
  z-index: 2;
  width: 35px;
  height: 35px;
  border: 1px solid #ccc;
  border-radius: 50px;
  margin-right: 72px;
  position: fixed;
  top: 20px;
}

.image-19 {
  width: 96px;
  display: block;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.image-20 {
  width: 500px;
  border-radius: 8px;
  position: absolute;
}

.profile-box {
  width: 500px;
  height: 500px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.profile-stripe {
  z-index: 2;
  width: 500px;
  height: 100px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 14px;
  display: flex;
  position: relative;
}

.image-21 {
  width: 73px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 70px;
  margin-right: 12px;
}

.text-block-10 {
  color: #fff;
}

.div-block-33 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.image-18-copy {
  z-index: 2;
  height: 29px;
  margin-right: 130px;
  position: fixed;
  top: 21px;
}

.name-icon {
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.div-block-35 {
  width: 21px;
  height: 21px;
  background-color: #dfff83;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-35.xs {
  width: 15px;
  height: 15px;
}

.div-block-35.xs.nf {
  margin-left: 5px;
}

.image-22 {
  width: 14px;
  height: 14px;
}

.image-22.xs {
  width: 10px;
  height: 10px;
}

.powerup-box-black {
  width: 70px;
  height: 70px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #000;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.powerup-box-black:hover {
  background-color: #dfff83;
}

.div-block-37 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.flash {
  width: 32px;
  filter: invert();
}

.flash.black {
  filter: none;
}

.div-block-38 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.profile-stripe-x {
  z-index: 2;
  width: 500px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-right: 13px;
  display: flex;
}

.powerup-box {
  width: 70px;
  height: 70px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  border: 0.75px solid #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-39 {
  width: 500px;
  margin-top: 12px;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 30px;
  display: flex;
}

.button-2 {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 10px;
}

.div-block-39-copy {
  width: 420px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 0;
  padding-left: 12px;
  padding-right: 0;
  display: flex;
}

.font-extra-small-copyxx {
  width: 210px;
  color: #000;
  text-align: left;
  letter-spacing: 0.2px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 11px;
  text-decoration: none;
  display: inline;
}

.font-extra-small-copyxx.right-bottom {
  margin-bottom: 0;
  position: fixed;
  top: auto;
  bottom: 15px;
  left: auto;
  right: 15px;
}

.font-extra-small-copyxx.right {
  text-align: right;
  margin-bottom: 0;
}

.font-extra-small-copyxx.white {
  width: 210px;
  min-width: 0;
  color: #fff;
  letter-spacing: 0.2px;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 100;
  line-height: 13px;
}

.link-block-2 {
  line-height: 14px;
  text-decoration: none;
  display: block;
}

.image-24 {
  height: 11px;
  margin-right: 5px;
}

.div-block-40 {
  width: 85px;
  justify-content: center;
  align-items: baseline;
  display: flex;
}

.frontgif {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
}

.image-51 {
  width: 110px;
  margin-top: 0;
}

.image-52 {
  cursor: pointer;
}

.image-53 {
  width: 17px;
  margin-top: -2px;
  margin-right: 7px;
}

.button-3 {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  font-size: 18px;
}

.div-block-41 {
  width: 95px;
  height: 95px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 110px;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.div-block-41:hover {
  transform: rotate(40deg);
}

.image-54 {
  width: 72px;
  cursor: pointer;
  transform: rotate(-10deg);
}

.div-block-40x {
  width: 500px;
  align-items: flex-end;
  margin-top: 12px;
  margin-bottom: 40px;
  padding-left: 0;
  padding-right: 30px;
  display: flex;
}

.text-span-2 {
  margin-right: 3px;
  font-weight: 400;
}

.text-span-2.xx {
  margin-right: 2px;
  font-weight: 300;
}

.text-span-3 {
  font-weight: 100;
}

.image-55 {
  width: 230px;
  height: 67px;
  margin-top: 10px;
  margin-left: -5px;
  padding-left: 0;
}

.div-block-42 {
  width: 500px;
  align-items: flex-start;
  margin-bottom: 20px;
  display: flex;
}

.link-block-3 {
  width: 300px;
  display: inline;
}

.sidebar-left {
  width: 58px;
  height: 100vh;
  border-right: 1px solid #ccc;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.image-56 {
  width: 22px;
  cursor: pointer;
  margin-top: 0;
}

.image-56.smaller {
  width: 23px;
  opacity: 0.5;
}

.image-57 {
  width: 32px;
  cursor: pointer;
  margin-bottom: 0;
}

.spacer-box-25 {
  width: 25px;
  height: 25px;
}

.spacer-box-25.smaller {
  height: 20px;
}

.profile-picture {
  z-index: 2;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 50px;
  margin-right: 0;
  position: static;
  top: auto;
  bottom: 12px;
  left: 12px;
  right: auto;
}

.bottom-nav-old {
  width: 155px;
  height: 116px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding-top: 15px;
  padding-left: 15px;
  display: none;
  position: fixed;
  top: auto;
  bottom: 20px;
  left: 80px;
  right: 0%;
}

.div-block-44 {
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.image-58 {
  width: 33px;
  opacity: 0.51;
  margin-right: 10px;
}

.image-58-copy {
  width: auto;
  height: 23px;
  opacity: 1;
  cursor: pointer;
  margin-right: 0;
}

.image-58-copy.signout {
  margin-left: 4px;
}

.image-58-copy.noti {
  height: 29px;
}

.image-58-copy.smallerlupe {
  height: 18px;
}

.image-58-copy.bigger {
  height: 27px;
}

.kleiner-user {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  display: none;
}

.image-59 {
  width: 20px;
  border-radius: 20px;
  margin-right: 3px;
}

.sidebar-right {
  width: 320px;
  height: 100vh;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-right: 1px #ccc;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.profile-nl {
  width: 33px;
  cursor: pointer;
  border-radius: 20px;
  margin-right: 5px;
}

.profile-nl.nf {
  width: 40px;
  border-radius: 70px;
  margin-right: 10px;
}

.profile-nl.nf.ava {
  width: 35px;
  margin-top: 15px;
  margin-right: 15px;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.div-block-45 {
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.div-block-45.analy {
  width: 150px;
  margin-bottom: 0;
  margin-right: 30px;
}

.dummy {
  width: 310px;
  opacity: 0.86;
  display: none;
  position: fixed;
  left: 500px;
}

.div-block-46 {
  margin-top: 55px;
  margin-left: 15px;
}

.div-block-47 {
  width: 288px;
  height: 45px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 15px;
  left: 15px;
  right: 0%;
}

.div-block-47:hover {
  border-color: #333;
}

.div-block-47.link-a-folder {
  width: 288px;
  height: 45px;
  cursor: pointer;
  bottom: 74px;
}

.bottom-nav {
  z-index: 2;
  width: 66px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-left: 0;
  display: none;
  position: fixed;
  top: auto;
  bottom: 70px;
  left: 0;
  right: 0%;
}

.see-all-button {
  opacity: 1;
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.2s;
}

.see-all-button:hover {
  border-color: #6d6d6d;
}

.see-all-button.padding {
  margin-bottom: 5px;
  margin-right: 5px;
}

.folder-title-box {
  align-items: center;
  display: flex;
}

.folder-title-box.analy {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
}

.center-box {
  margin-left: 66px;
  padding-top: 55px;
}

.image-60 {
  width: auto;
  height: 15px;
  margin-right: 5px;
}

.folder-title-small {
  display: flex;
}

.image-18-copy-copyx {
  z-index: 2;
  height: 29px;
  margin-right: 124px;
  position: fixed;
  top: 21px;
}

.button-nl-home {
  color: #000;
  text-align: center;
  border: 1px #e4e4e4;
  border-radius: 8px;
  padding: 9px 38px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 14px;
  font-weight: 200;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
  position: fixed;
  top: 25px;
  bottom: auto;
  left: 25px;
  right: auto;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.button-nl-home:hover {
  color: #000;
  border-style: none;
  border-color: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
}

.button-nl-home.right {
  font-size: 13px;
  left: auto;
  right: 25px;
}

.body-nl-homepage {
  padding: 0 25px;
  font-family: Newfoundation Whyte, sans-serif;
  font-weight: 300;
}

.body-nl-homepage.newlife {
  padding: 0;
}

.big-banner-button {
  height: 29.5%;
  color: #000;
  text-align: center;
  border: 1px #e4e4e4;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 28px;
  padding-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 15px;
  font-weight: 200;
  text-decoration: none;
  display: flex;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.big-banner-button.right {
  left: auto;
  right: 30px;
}

.big-banner-button.k1 {
  transition: all 0.2s;
}

.big-banner-button.k1:hover {
  background-image: none;
}

.big-banner-button.k2 {
  transition: all 0.2s;
}

.logo-nl {
  width: 64px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: fixed;
  top: 25px;
}

.div-block-48 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-49 {
  height: 80vh;
  margin-top: 20vh;
}

.image-61 {
  width: 31%;
  margin-top: -0.3%;
}

.image-61.k3 {
  width: 17%;
  margin-top: 0%;
}

.image-61.k2 {
  width: 35%;
  margin-top: 0%;
}

.div-block-50 {
  width: 96vw;
  height: 22.3%;
  background-color: rgba(182, 155, 155, 0.31);
  border-radius: 8px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  display: none;
  position: absolute;
}

.div-block-51 {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-52 {
  width: 170px;
  height: 170px;
  cursor: pointer;
  border: 1px rgba(204, 204, 204, 0.47);
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 5px;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
}

.div-block-52:hover {
  border-color: #ccc;
  transform: translate(0, -2px);
}

.center-box-grid {
  flex-wrap: wrap;
  margin-top: 47px;
  margin-left: 66px;
  margin-right: 320px;
  padding-top: 0;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.image-62 {
  height: 100%;
  object-fit: contain;
  display: block;
}

.link-block-5 {
  margin-bottom: 33px;
}

.link-block-5.w--current {
  margin-bottom: 0;
}

.view-link {
  opacity: 0.3;
  transition: all 0.2s;
}

.view-link:hover,
.view-link.w--current {
  opacity: 1;
}

.center-box-voting {
  height: 100vh;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-left: 66px;
  margin-right: 320px;
  padding-top: 0;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.image-62-voting {
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

.text-block-11 {
  font-size: 12px;
  line-height: 16px;
}

.text-post {
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 11px;
  line-height: 15px;
}

.div-block-53 {
  width: 100%;
  height: 62px;
  border-bottom: 1px solid #ccc;
  padding-top: 15px;
  padding-left: 15px;
}

.div-block-54 {
  margin-left: 15px;
}

.div-block-55 {
  display: flex;
}

.image-63 {
  width: auto;
  height: 13px;
  margin-right: 5px;
}

.div-block-56 {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.div-block-57 {
  height: 90%;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.div-block-58 {
  margin-top: 1px;
  display: flex;
}

.text-span-4 {
  font-weight: 100;
}

.div-block-59 {
  width: 50%;
  height: 45px;
  background-color: #dfff83;
  border-radius: 110px;
  margin-right: 130px;
  padding-right: 400px;
  position: fixed;
  top: 15px;
  bottom: auto;
  left: 80px;
}

.link-block-6,
.link-block-7 {
  text-decoration: none;
}

.textbox {
  z-index: 5;
  height: 45px;
  color: #ccc;
  border: 1px solid #ccc;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 82px;
  margin-right: 403px;
  padding-left: 15px;
  font-weight: 400;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 15px;
  left: 0%;
  right: 0%;
}

.image-64 {
  height: 19px;
  opacity: 0.26;
  margin-right: 15px;
}

.div-block-61 {
  width: 100%;
  height: 60px;
}

.div-block-60-copy {
  height: 45px;
  border: 1px #ccc;
  border-radius: 8px;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 15px;
  left: 70px;
  right: 320px;
}

.lupe-nav-origin {
  width: 15px;
  opacity: 0.6;
  text-align: left;
  cursor: pointer;
  align-items: center;
  margin-top: 0;
  margin-left: 13px;
  margin-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  display: block;
  position: static;
  top: 0;
  bottom: 0;
}

.div-block-62 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.text-block-12 {
  color: #b4b4b4;
  margin-top: 4px;
  font-size: 11px;
  text-decoration: none;
}

.analytics-box-list {
  flex-flow: column;
  margin-top: 70px;
  margin-left: 66px;
  margin-right: 320px;
  padding-top: 0;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.analytics-box-list.nf {
  width: 500px;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
}

.analytics-box-list.nf.networks {
  height: auto;
  justify-content: flex-start;
}

.div-block-52-analy {
  width: auto;
  height: 150px;
  cursor: pointer;
  border: 1px rgba(204, 204, 204, 0.47);
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 15px;
  padding: 35px 0;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
}

.div-block-52-analy:hover {
  border-color: #ccc;
  transform: translate(0, -2px);
}

.div-block-52-analy.top-list {
  height: auto;
  border-bottom-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.div-block-52-analy.top-list:hover {
  transform: none;
}

.text-block-13 {
  color: #000;
  margin-left: 50px;
  margin-right: 100px;
  font-size: 34px;
}

.div-block-63 {
  width: 130px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.image-62-analy {
  width: 80px;
  height: 100%;
  object-fit: contain;
  display: block;
}

.text-post-analy {
  width: 250px;
  color: #000;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  line-height: 19px;
}

.div-block-63-copy {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.analy-top-listing {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0;
  display: flex;
}

.text-block-12-copy {
  color: #b4b4b4;
  margin-top: 4px;
  font-size: 11px;
  text-decoration: none;
}

.top-listing {
  color: #000;
  margin-top: 0;
  margin-right: 0;
  font-size: 11px;
  text-decoration: none;
}

.top-listing.user {
  margin-right: 154px;
}

.top-listing.score {
  margin-right: 110px;
}

.top-listing.name {
  margin-right: 150px;
}

.text-block-12-analy {
  width: auto;
  color: #000;
  margin-top: 0;
  font-size: 14px;
  text-decoration: none;
}

.div-block-64 {
  width: 130px;
}

.spacer-60 {
  width: 100%;
  height: 60px;
}

.menu-nf {
  width: 200px;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.image-65 {
  width: 30px;
  height: 30px;
  margin-right: 0;
}

.image-65.networks {
  width: 22px;
}

.image-65.connections {
  width: 20px;
}

.nf-menu {
  font-size: 20px;
  font-weight: 100;
}

.nf-menu.signout {
  z-index: 3;
  color: #b6b6b6;
  position: fixed;
  top: auto;
  bottom: 15px;
  left: 15px;
  right: auto;
}

.nf-menu-item {
  color: #000;
  align-items: center;
  margin-bottom: 6px;
  padding: 4px 10px 4px 4px;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
}

.nf-menu-item:hover {
  background-color: #f3f3f3;
  border-radius: 6px;
}

.nf-menu-item.active {
  color: #000;
  cursor: pointer;
  background-color: #f3f3f3;
  border-radius: 6px;
  text-decoration: none;
}

.icon-box {
  justify-content: center;
  margin-right: 6px;
  display: flex;
}

.font-l {
  font-size: 24px;
  font-weight: 100;
}

.font-l.subnav {
  margin-right: 25px;
}

.font-l.subnav.grey {
  color: #a5a5a5;
}

.font-l.subnav.grey:hover {
  color: #000;
}

.font-l.subnav.smaller {
  font-size: 18px;
}

.font-l.grey {
  color: #a5a5a5;
}

.font-l.grey:hover {
  color: #000;
}

.font-l.grey.smaller {
  font-size: 18px;
}

.nf-profile-div {
  width: auto;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  display: flex;
}

.div-block-66 {
  width: 230px;
  flex-direction: column;
  display: flex;
}

.div-block-67 {
  align-items: center;
  display: flex;
}

.div-block-67.networks {
  width: 370px;
  justify-content: space-between;
}

.div-block-68 {
  align-items: center;
  margin-bottom: 2px;
  display: flex;
}

.font-xs {
  font-size: 12px;
}

.image-66 {
  width: 300px;
  height: auto;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.image-67 {
  width: 50px;
  height: 50px;
}

.logo-nsf {
  width: 115px;
  position: fixed;
  top: 20px;
  left: 20px;
}

.div-block-69 {
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.div-block-69.first-app {
  width: 400px;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.div-block-69-copy {
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.div-block-68-copy,
.div-block-68-new {
  align-items: center;
  margin-bottom: 2px;
  display: flex;
}

.image-68 {
  width: 370px;
  border: 1px #dfdfdf;
  border-radius: 6px;
  margin-bottom: 15px;
  padding-top: 2px;
  padding-bottom: 0;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
}

.image-69 {
  width: 60px;
  margin-right: 10px;
}

.link-3 {
  width: 50px;
  height: 28px;
  color: #000;
  background-color: #fff;
  border-radius: 80px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  display: flex;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
}

.div-block-70 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.div-block-71 {
  position: fixed;
  top: 140px;
}

.logo-box-left {
  z-index: 999;
  position: fixed;
  top: 10px;
  left: 10px;
}

.image-70 {
  width: 320px;
  margin-top: 5px;
}

.image-71 {
  height: 17px;
  position: fixed;
  top: 23px;
  bottom: auto;
  left: auto;
  right: 112px;
}

.div-block-72 {
  position: fixed;
  top: 15px;
  bottom: auto;
  left: auto;
  right: 15px;
}

.text-block-14 {
  width: 350px;
  text-align: center;
  font-size: 20px;
  font-weight: 100;
  line-height: 27px;
}

.text-block-14.left-aligned {
  text-align: left;
}

.text-block-14.left-aligned.sp {
  display: inline;
}

.button-right-top-copy-copy-copy {
  width: 350px;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 1px #e4e4e4;
  border-radius: 110px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  padding: 15px 0;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 17px;
  font-weight: 200;
  text-decoration: none;
  transition: all 0.2s;
  display: inline-block;
  box-shadow: 1px 0 17px rgba(0, 0, 0, 0.12);
}

.button-right-top-copy-copy-copy:hover {
  background-color: #f8f8f8;
  border-color: #5c5c5c;
  transform: translate(0, -1px);
  box-shadow: 2px 0 22px rgba(0, 0, 0, 0.25);
}

.image-72 {
  width: 350px;
}

.footer-3 {
  color: #1c1c1c;
  background-color: #f8f8f8;
  border: 1px #b6aeae;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 80px;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
  z-index: 1001;
  position: sticky;
  margin-top: 160px;
}

.div-block-79 {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
}

.text-size-tiny-2 {
  letter-spacing: 0.2px;
  font-size: 12px;
  font-weight: 200;
}

.button-4 {
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 0.05rem solid #fff;
  border-radius: 0.2rem;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.9rem;
  font-size: 0.85rem;
  font-weight: 400;
  transition: border-color 0.3s, background-color 0.3s;
}

.button-4:hover {
  background-color: #2c2c2c;
}

.button-4.is-footer-subscribe {
  color: #fff;
  background-color: #fff;
  border-style: none;
  margin-left: -2rem;
  transition: background-color 0.3s;
}

.button-4.is-footer-subscribe:hover {
  background-color: #d9d9d9;
}

.footer_top {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  display: flex;
}

.div-block-77 {
  flex-direction: column;
  margin-top: 20px;
  margin-right: 30px;
  display: flex;
}

.footer_logo-copy-3 {
  height: 32px;
  filter: invert();
  margin-bottom: 0;
}

.footer-logo_link-2.w--current {
  background-color: rgba(255, 255, 255, 0);
}

.nav_link-copy-copy-copy-2 {
  color: #fff;
  letter-spacing: 0.5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin: 0 1.7rem;
  padding: 0 0 0;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4;
  transition: all 0.2s;
}

.nav_link-copy-copy-copy-2:hover {
  opacity: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.48);
  margin: 0 1.7rem;
  padding: 0 0 0;
}

.nav_link-copy-copy-copy-2.w--current {
  opacity: 1;
  color: #fff;
  padding: 0 0 1px;
}

.nav_link-copy-copy-copy-2.w--current:hover {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.nav_link-copy-copy-copy-2.border {
  color: #000;
  letter-spacing: 0;
  background-color: rgba(231, 231, 231, 0);
  border: 1px solid #999;
  border-radius: 9px;
  margin-top: 30px;
  margin-left: 0;
  margin-right: 0;
  padding: 0.3rem 0.7rem;
  font-size: 15px;
  text-decoration: none;
}

.div-block-76 {
  text-transform: uppercase;
  flex-direction: column;
  margin-top: 30px;
  font-size: 12px;
  display: flex;
}

.footer_form-wrapper {
  margin-bottom: 0;
  display: none;
}

.padding-global {
  z-index: 1;
  margin-top: 0;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.form_message-success {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0.5rem;
  padding: 1.25rem;
}

.form_message-success.is-footer-form {
  background-color: rgba(255, 255, 255, 0.03);
}

.footer_link-2025 {
  color: #000;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  background-color: rgba(231, 231, 231, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  font-weight: 100;
  line-height: 1.4;
  text-decoration: none;
  transition: color 0.3s;
}

.footer_link-2025:hover {
  opacity: 1;
  border-bottom: 1px solid #c9c9c9;
}

.form_input-2 {
  min-height: 3rem;
  color: #000;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  padding: 1rem 4rem 1rem 1rem;
  font-family: Newfoundation Whyte, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  transition: border-color 0.2s;
}

.form_input-2:focus {
  border-width: 0.125rem;
  border-color: #6b7cff;
}

.form_input-2::-ms-input-placeholder {
  color: rgba(29, 33, 48, 0.6);
  font-size: 1rem;
}

.form_input-2::placeholder {
  color: rgba(29, 33, 48, 0.6);
  font-size: 1rem;
}

.form_input-2.is-footer-form {
  min-height: 3.5rem;
  color: #fff;
  background-color: #2b2e3c;
  margin-bottom: 0;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
}

.form_input-2.is-footer-form:focus {
  border-color: rgba(224, 228, 252, 0.2);
}

.form_input-2.is-footer-form::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form_input-2.is-footer-form::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.footer_top-right {
  width: 30%;
  height: 160px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2rem;
  display: flex;
}

.text-size-medium {
  text-align: left;
  font-size: 1.125rem;
  font-weight: 300;
}

.text-size-medium.text-weight-semibold {
  text-align: left;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
}

.footer_links-list-2 {
  width: 60%;
  flex-flow: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: flex-start;
  justify-content: flex-end;
  justify-items: stretch;
  display: flex;
}

.div-block-78 {
  height: 15px;
}

.text-size-tiny-copy-3 {
  color: #000;
  letter-spacing: 0.2px;
  background-color: rgba(231, 231, 231, 0);
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  font-weight: 200;
  text-decoration: none;
}

.form_message-error {
  border-radius: 0.5rem;
  margin-top: 0.75rem;
  padding: 1rem;
}

.form_message-error.is-footer-form {
  color: #fff;
}

.container-large-2 {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.footer_form {
  color: #fff;
  align-items: center;
  display: flex;
}

.footer_links-column-2 {
  flex-direction: column;
  flex: 1;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  margin-right: 0;
  display: flex;
}

.spacer-bottom {
  width: 100%;
  height: 15px;
  background-color: #fff;
}

.text-block-15 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Romie, sans-serif;
  font-size: 50px;
  font-weight: 400;
}

.image-73 {
  width: 350px;
}

.image-74 {
  width: 25px;
  margin-top: -7px;
  margin-left: 2px;
  margin-right: 1px;
  display: inline-block;
}

.div-block-80 {
  width: 350px;
}

@media screen and (max-width: 767px) {
  .padding-global {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .footer_links-list-2 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: flex;
  }

  .footer_links-column-2 {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 479px) {
  .footer_top-right {
    width: 100%;
    max-width: 32rem;
    min-width: auto;
    margin-left: 0;
  }

  .footer_links-list-2 {
    width: 100%;
    margin-top: 2rem;
  }
  .menu {
    display: none;
  }

  .body {
    padding: 15px;
  }

  .body.newlife {
    padding-left: 15px;
    padding-right: 15px;
  }

  .div-block-7 {
    width: auto;
  }

  .font-small-x.left {
    font-size: 14px;
  }

  .image-5 {
    width: auto;
    height: auto;
  }

  .div-block-15 {
    width: 100%;
    height: auto;
  }

  .text-block-7 {
    width: 80%;
    font-size: 12px;
    line-height: 130%;
  }

  .text-block-7-copy {
    width: auto;
    margin-bottom: 5px;
    font-weight: 200;
  }

  .nav-right {
    display: flex;
  }

  .h2-x {
    width: auto;
    margin-bottom: 5px;
    font-weight: 200;
  }

  .div-block-7-copy {
    width: auto;
  }

  .h1-romie {
    font-size: 21px;
  }

  .small-icon-right,
  .small-icon-right._100 {
    height: 15px;
    margin-right: 3px;
  }

  .font-small-subnavi {
    font-size: 12px;
  }

  .image-12 {
    display: none;
  }

  .image-13,
  .image-13-copy {
    height: 30px;
  }

  .slider-2x {
    width: auto;
    height: auto;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .newheadline {
    text-align: center;
    letter-spacing: 0;
    -webkit-text-stroke-width: 0.65px;
    font-size: 6vw;
    line-height: 29px;
  }

  .image-18 {
    width: 32px;
    height: 32px;
    margin-right: 70px;
    top: 15px;
  }

  .image-19 {
    margin-top: -6px;
  }

  .image-20,
  .profile-box {
    width: auto;
  }

  .image-18-copy {
    margin-right: 117px;
    top: 16px;
  }

  .powerup-box-black {
    flex: none;
  }

  .profile-stripe-x {
    width: auto;
  }

  .frontgif {
    display: flex;
  }

  .search {
    height: 18px;
    position: fixed;
    top: 18px;
    right: 184px;
  }

  .sidebar-left {
    z-index: 3;
    width: 100vw;
    height: 58px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    border-right-style: none;
    flex-direction: row;
    padding-bottom: 12px;
    padding-left: 0;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .image-57 {
    width: 31px;
  }

  .spacer-box-25 {
    width: 30px;
  }

  .profile-picture {
    z-index: 3;
    width: 33px;
    height: 33px;
    margin-right: 0;
    top: auto;
    bottom: 12px;
    left: auto;
    right: 12px;
  }

  .sidebar-right {
    display: none;
  }

  .profile-nl.nf {
    width: 35px;
  }

  .image-18-copy-copyx {
    margin-right: 117px;
    top: 16px;
  }

  .button-nl-home {
    border-radius: 6px;
    padding: 5px 18px 4px;
    font-size: 11px;
    top: 15px;
    left: 15px;
  }

  .button-nl-home.right {
    border-radius: 6px;
    padding-top: 5px;
    padding-bottom: 4px;
    font-size: 11px;
    right: 15px;
  }

  .body-nl-homepage {
    padding: 15px;
  }

  .big-banner-button {
    height: 25%;
    border-radius: 6px;
  }

  .big-banner-button.k1,
  .big-banner-button.k2 {
    border-radius: 6px;
  }

  .logo-nl {
    width: 39px;
    top: 15px;
  }

  .div-block-49 {
    margin-top: 100px;
  }

  .image-61 {
    width: 100%;
  }

  .image-61.k3 {
    width: 60%;
  }

  .image-61.k2 {
    width: 100%;
  }

  .div-block-52 {
    width: 45%;
    height: 44vw;
    border-radius: 6px;
    flex-flow: wrap;
    margin-bottom: 40px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .center-box-grid {
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .link-block-5 {
    z-index: 3;
    margin-bottom: 0;
    margin-left: 12px;
    margin-right: 20px;
    position: relative;
  }

  .view-link {
    flex-direction: row;
    display: block;
  }

  .view-link.w--current {
    display: block;
  }

  .center-box-voting {
    flex-wrap: nowrap;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 0;
    padding-right: 0;
  }

  .text-post {
    font-size: 8px;
    line-height: 10px;
  }

  .div-block-57 {
    height: auto;
  }

  .div-block-59 {
    width: 90%;
    margin-left: 15px;
    margin-right: 15px;
    padding-right: 0;
    left: auto;
  }

  .lupe-nav-origin {
    width: 15px;
    margin-left: 5%;
    margin-right: 3%;
  }

  .div-block-62 {
    width: 100vw;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: fixed;
  }

  .analytics-box-list {
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .analytics-box-list.nf {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .div-block-52-analy {
    width: 45%;
    height: 44vw;
    border-radius: 6px;
    flex-flow: wrap;
    margin-bottom: 40px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .text-post-analy {
    font-size: 8px;
    line-height: 10px;
  }

  .menu-nf {
    display: none;
  }

  .nf-profile-div {
    width: auto;
  }

  .font-xs {
    font-size: 11px;
  }

  .text-block-14,
  .button-right-top-copy-copy-copy {
    width: 100%;
  }

  .footer-3 {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 30px;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .div-block-79 {
    margin-bottom: 40px;
  }

  .text-size-tiny-2 {
    font-size: 11px;
  }

  .button-4 {
    font-size: 0.8rem;
  }

  .button-4.is-footer-subscribe {
    margin-top: 0.5rem;
    margin-left: 0;
  }

  .footer_top {
    flex-direction: column;
  }

  .div-block-77 {
    margin-right: 20px;
  }

  .footer_logo-copy-3 {
    height: 25px;
  }

  .nav_link-copy-copy-copy-2.border {
    margin-top: 10px;
    font-size: 14px;
  }

  .nav_link-copy-copy-copy-2.border.w--current {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  .div-block-76 {
    margin-top: 0;
  }

  .footer_link-2025 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 11px;
  }

  .form_input-2.is-footer-form {
    margin-right: 0;
  }

  .footer_top-right {
    height: auto;
    margin-bottom: 40px;
  }

  .text-size-medium.text-weight-semibold {
    font-size: 11px;
  }

  .footer_links-list-2 {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0;
  }

  .text-size-tiny-copy-3 {
    font-size: 11px;
  }

  .container-large-2 {
    height: auto;
    margin-bottom: 20px;
  }

  .footer_form {
    background-color: #2b2e3c;
    border-radius: 1.25rem;
    flex-direction: column;
    align-items: stretch;
    padding: 0.5rem;
  }

  .footer_links-column-2 {
    margin-bottom: 0;
    margin-right: 5px;
    font-size: 0.8rem;
  }

  .div-block-80 {
    width: 100%;
  }
}
