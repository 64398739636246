.nl-onboarding-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nl-onboarding-form .nl-onboarding-input > input {
  padding: 0px !important;
  border: none !important;
}
.nl-onboarding-form .ant-input-affix-wrapper:before {
  display: none;
}
.nl-onboarding-form .ant-form-item-has-error .error-circle-form {
  display: inherit;
}
.nl-onboarding-form .ant-input-affix-wrapper {
  display: flex;
  margin: 0 auto;
}
.nl-onboarding-form .nl-onboarding-input-hash {
  font-size: clamp(15px, 1.5vw, 2vw);
}
@media screen and (max-width: 610px) {
  .nl-onboarding-form .nl-onboarding-input-hash {
    font-size: clamp(5px, 1.5vw, 2vw) !important;
  }
}
@media screen and (min-width: 1259px) {
  .nl-onboarding-form .nl-onboarding-input-code input,
  .nl-onboarding-form .nl-onboarding-input-code .ant-input-suffix {
    font-size: clamp(20px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 1258px) {
  .nl-onboarding-form .nl-onboarding-input-code input,
  .nl-onboarding-form .nl-onboarding-input-code .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 830px) {
  .nl-onboarding-form .nl-onboarding-input-code input,
  .nl-onboarding-form .nl-onboarding-input-code .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 650px) {
  .nl-onboarding-form .nl-onboarding-input-code input,
  .nl-onboarding-form .nl-onboarding-input-code .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 530px) {
  .nl-onboarding-form .nl-onboarding-input-code input,
  .nl-onboarding-form .nl-onboarding-input-code .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 470px) {
  .nl-onboarding-form .nl-onboarding-input-code input,
  .nl-onboarding-form .nl-onboarding-input-code .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
.nl-onboarding-form .nl-onboarding-input-code input:active::placeholder,
.nl-onboarding-form .nl-onboarding-input-code input:focus::placeholder,
.nl-onboarding-form .nl-onboarding-input-code input:visited::placeholder,
.nl-onboarding-form
  .nl-onboarding-input-code
  .ant-input-suffix:active::placeholder,
.nl-onboarding-form
  .nl-onboarding-input-code
  .ant-input-suffix:focus::placeholder,
.nl-onboarding-form
  .nl-onboarding-input-code
  .ant-input-suffix:visited::placeholder {
  color: #959595 !important;
}
@media screen and (min-width: 1259px) {
  .nl-onboarding-form .nl-onboarding-input-phone input,
  .nl-onboarding-form .nl-onboarding-input-phone .ant-input-suffix {
    font-size: clamp(20px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 1258px) {
  .nl-onboarding-form .nl-onboarding-input-phone input,
  .nl-onboarding-form .nl-onboarding-input-phone .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 830px) {
  .nl-onboarding-form .nl-onboarding-input-phone input,
  .nl-onboarding-form .nl-onboarding-input-phone .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 650px) {
  .nl-onboarding-form .nl-onboarding-input-phone input,
  .nl-onboarding-form .nl-onboarding-input-phone .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 530px) {
  .nl-onboarding-form .nl-onboarding-input-phone input,
  .nl-onboarding-form .nl-onboarding-input-phone .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 470px) {
  .nl-onboarding-form .nl-onboarding-input-phone input,
  .nl-onboarding-form .nl-onboarding-input-phone .ant-input-suffix {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
.nl-onboarding-form .nl-onboarding-input-phone input:active::placeholder,
.nl-onboarding-form .nl-onboarding-input-phone input:focus::placeholder,
.nl-onboarding-form .nl-onboarding-input-phone input:visited::placeholder,
.nl-onboarding-form
  .nl-onboarding-input-phone
  .ant-input-suffix:active::placeholder,
.nl-onboarding-form
  .nl-onboarding-input-phone
  .ant-input-suffix:focus::placeholder,
.nl-onboarding-form
  .nl-onboarding-input-phone
  .ant-input-suffix:visited::placeholder {
  color: #959595 !important;
}
.nl-onboarding-form .nl-legacyImport-email-input {
  text-align: left;
}
@media screen and (min-width: 1259px) {
  .nl-onboarding-form .nl-legacyImport-email-input {
    font-size: clamp(25px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 1258px) {
  .nl-onboarding-form .nl-legacyImport-email-input {
    font-size: clamp(18px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 830px) {
  .nl-onboarding-form .nl-legacyImport-email-input {
    font-size: clamp(18px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 650px) {
  .nl-onboarding-form .nl-legacyImport-email-input {
    font-size: clamp(18px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 530px) {
  .nl-onboarding-form .nl-legacyImport-email-input {
    font-size: clamp(18px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 470px) {
  .nl-onboarding-form .nl-legacyImport-email-input {
    font-size: clamp(18px, 1.5vw, 2vw) !important;
  }
}
.nl-onboarding-form .nl-legacyImport-email-input:active::placeholder,
.nl-onboarding-form .nl-legacyImport-email-input:focus::placeholder,
.nl-onboarding-form .nl-legacyImport-email-input:visited::placeholder {
  color: #959595 !important;
}
@media screen and (min-width: 1259px) {
  .nl-onboarding-form .nl-onboarding-input-phone-disabled {
    font-size: clamp(20px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 1258px) {
  .nl-onboarding-form .nl-onboarding-input-phone-disabled {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 830px) {
  .nl-onboarding-form .nl-onboarding-input-phone-disabled {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 650px) {
  .nl-onboarding-form .nl-onboarding-input-phone-disabled {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 530px) {
  .nl-onboarding-form .nl-onboarding-input-phone-disabled {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
@media screen and (max-width: 470px) {
  .nl-onboarding-form .nl-onboarding-input-phone-disabled {
    font-size: clamp(13px, 1.5vw, 2vw) !important;
  }
}
.nl-onboarding-form .nl-onboarding-form-item {
  width: 100%;
}
.nl-onboarding-form
  .nl-onboarding-form-item
  .ant-form-item-control-input-content {
  text-align: center;
}
.nl-onboarding-form .nl-onboarding-form-item .ant-form-item-explain-error {
  width: 70%;
  margin: auto;
}
.nl-onboarding-link {
  color: #959595 !important;
}

.nl-onboarding-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  margin: 0 auto;
}

.nl-legacyImport-title {
  margin-top: 60px;
  margin-bottom: 50px;
}
.nl-legacyImport-checkYourEmail-description {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.support-box,
.nl-domain-selector-infobox {
  background: #959595;
  white-space: pre-wrap;
  border-radius: 8px;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 69px;
  margin: 0 auto;
}

.masked-input-error,
.masked-input-error:focus,
.masked-input-error:hover {
  border-color: #c46ef7 !important;
  color: #c46ef7 !important;
}

.nl-domain-presale__masked-input,
.nl-onboarding-input {
  text-align: center;
  width: 70%;
  height: auto !important;
  font-variant: none;
}

.nl-domain-presale__masked-input::placeholder {
  text-align: end !important;
}

.nl-domain-presale__masked-input:focus::placeholder {
  color: black !important;
}

.nl-domain-presale__footer-paragraph {
  text-align: center;
  width: 100%;
  color: #959595;
  margin-bottom: 40px !important;
}

.nl-domain-presale__info-text__wrapper {
  width: 100%;
}

.nl-domain-presale__masked-input {
  margin-bottom: 24px;
}
@media screen and (min-width: 451px) {
  .nl-domain-presale__masked-input {
    font-size: clamp(20px, 120px, 2vw);
  }
}
@media screen and (max-width: 450px) {
  .nl-domain-presale__masked-input {
    font-size: clamp(20px, 9vw, 2vw);
  }
}

#sign-up-form {
  width: 100%;
}

/* ANTD STYLING */

/* .ant-input,
.ant-input-affix-wrapper {
  border-radius: 0;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  outline: none;
}
.ant-input:active,
.ant-input:focus,
.ant-input-affix-wrapper:active,
.ant-input-affix-wrapper:focus {
  outline-color: transparent;
  outline-style: none;
  box-shadow: none;
}

.ant-input:hover,
.ant-input,
.ant-input::placeholder {
  border-color: #ffffff;
  color: #e5e5e5;
} */
/* .ant-input:focus, */
.app-main-full-height .has-value {
  color: black;
  border-color: black;
}
.ant-form .ant-form-item {
  margin-bottom: 24px;
}

.ant-form .ant-form-item {
  margin-bottom: 24px;
}

.ant-form-item textarea.ant-input {
  height: 100px;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-explain.ant-form-item-explain-error,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper {
  color: #c46ef7;
  border-color: #c46ef7 !important;
  box-shadow: none !important;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input::placeholder,
.ant-form-item-explain.ant-form-item-explain-error::placeholder,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus::placeholder,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper::placeholder {
  color: #c46ef7 !important;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input
  path,
.ant-form-item-explain.ant-form-item-explain-error path,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus
  path,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper
  path {
  fill: #c46ef7 !important;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  border-color: #c46ef7 !important;
}
.ant-form-item-explain-error {
  padding-top: 5px;
  color: #c46ef7;
  text-align: left;
}
.ant-form-item .error-circle-form {
  display: none;
}
.ant-form-item-has-error .error-circle-form {
  display: block;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #e4e4e4;
}
.ant-form-item-control {
  margin: 0 auto;
}
#sign-up-form .ant-btn,
/* TODO: change content layout on newlife */
.app-main-full-height {
  width: 100%;
}
#sign-up-form.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: 0;
}
#sign-up-form .ant-input-affix-wrapper {
  padding: 0;
  border: none;
  box-shadow: none;
}
